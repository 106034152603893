import React, { Component } from 'react';
import Navigation from './Navigation.js'
import Footer from './Footer.js'
import './App.css';


class About extends Component {
  
  render() {
    return (
      <div>
        <header>
        <img src="/img/matrix-row-reduction-calculator-logo.png" className="logo" alt="Matrix Row Reduction Calculator" />
         <a href="/" className="logo-title">Matrix Row Reduction Calculator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
         <div className="section">
          <h1>About</h1>
          <div>
            <p>Our goal is to make linear algebra calculations as simple and intuitive as possible for students, educators, and professionals. 
                <br />This tool allows you to easily compute the Reduced Row Echelon Form (RREF) of a matrix, a fundamental concept in solving systems of linear equations.
            </p>
            
            <h2>What is RREF?</h2>
            <p>The Reduced Row Echelon Form of a matrix is a simplified version of the matrix, where each row starts with a leading 1, and all the elements in the column above and below this leading 1 are zero.
             <br/>It’s a crucial step in linear algebra, particularly for solving linear systems, finding inverses of matrices, and analyzing the rank of a matrix.</p>
            

            <h2>Why Use Our Calculator</h2>
                <ul>
                    <li><b>User-Friendly Interface:</b> Our calculator is designed to be simple and intuitive, allowing you to enter your matrix dimensions, fill in the matrix values, and instantly compute the RREF.</li>
                    <li><b>Step-by-Step Solutions:</b> Unlike many other tools, we provide detailed calculation steps, helping you understand each operation performed to reach the RREF. This makes it ideal for learning and teaching.</li>
                    <li><b>Responsive and Fast:</b> The calculator works seamlessly on any device, providing you with quick and accurate results without any hassle.</li>
                    <li><b>Customizable:</b> You can adjust the dimensions of the matrix to suit your needs and get an RREF for matrices of various sizes.</li>
                </ul>

            <h2>Who Can Benefit</h2>
                <ul>
                    <li><b>Students:</b> Use this calculator to verify your homework or get step-by-step explanations for your matrix problems. </li>
                    <li><b>Educators:</b> Make your teaching more interactive by showing step-by-step solutions in real-time.</li>
                    <li><b>Engineers, Economists, and Analysts:</b> Quickly solve systems of linear equations and analyze matrices in your field of work.</li>
                </ul>

                <br />
            

               <p>Whether you’re just starting out with linear algebra or you’re a seasoned professional, our calculator provides you with the tools and clarity you need to solve matrices effortlessly.</p>

               <p>Thank you for using our calculator. We hope it makes your learning and problem-solving journey smoother!</p>
            

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;