import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';


class Footer extends Component {
  
  render() {
    return (
    <div className="footer-container">
        <div className="policy-container">
          <div id="footer-1">
                <Link to="/terms">Terms & Condition |</Link>
                <Link to="/disclaimer"> Disclaimer | </Link>
                <Link to="/privacy-policy" >Privacy Policy |</Link>
                <Link to="/contact"> Contact</Link>
            </div>
        </div>
      <section id="footer-2">Copyright© 2024 Matrix Row Reduction Calculator. All Rights Reserved.</section>
    </div>
    );
  }
}

export default Footer;