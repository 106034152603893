import React from 'react';
import './App.css';

const MatrixInput = ({ matrix, handleInputChange }) => {
  return (
    <div className="matrix-input">
      {matrix.map((row, rowIndex) => (
        <div key={rowIndex} className="matrix-row">
          {row.map((col, colIndex) => (
            <input
              key={colIndex}
              type="number"
              value={matrix[rowIndex][colIndex]}
              onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
              className="matrix-cell"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MatrixInput;