import React from 'react';
import './App.css';

const ResultMatrix = ({ rrefMatrix }) => {
  return (
    <div className="result-matrix">
      <br />
      <h2 className="solution">RREF Matrix (Solution)</h2>
      {rrefMatrix.map((row, rowIndex) => (
        <div key={rowIndex} className="result-row">
          {row.map((col, colIndex) => (
            <div key={colIndex} className="result-cell">
              {col.toFixed(0)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ResultMatrix;