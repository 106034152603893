import React, { useState } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import MatrixInput from './MatrixInput';
import ResultMatrix from './ResultMatrix';
import Calculation from './Calculation';
import Footer from './Footer.js'


// Helper function to compute RREF with matrix snapshots
const computeRREF = (matrix) => {
  let steps = [];
  const cloneMatrix = JSON.parse(JSON.stringify(matrix)); // Deep clone to avoid mutation
  let lead = 0;
  const rowCount = cloneMatrix.length;
  const columnCount = cloneMatrix[0].length;

  // Helper function to save the current matrix state
  const saveStep = (operation) => {
    steps.push({
      operation,
      matrix: cloneMatrix.map(row => [...row]) // Deep clone matrix state
    });
  };

  for (let r = 0; r < rowCount; r++) {
    if (lead >= columnCount) return { matrix: cloneMatrix, steps };
    let i = r;
    while (cloneMatrix[i][lead] === 0) {
      i++;
      if (i === rowCount) {
        i = r;
        lead++;
        if (lead === columnCount) return { matrix: cloneMatrix, steps };
      }
    }

    // Swap rows if necessary
    [cloneMatrix[i], cloneMatrix[r]] = [cloneMatrix[r], cloneMatrix[i]];
    saveStep(`Swapped row ${i + 1} with row ${r + 1}`);

    // Normalize the row by dividing by the lead element
    let val = cloneMatrix[r][lead];
    for (let j = 0; j < columnCount; j++) {
      cloneMatrix[r][j] /= val;
    }
    saveStep(`Divide row ${r + 1} by ${val}`);

    // Make all rows below and above 0 in the current column
    for (let i = 0; i < rowCount; i++) {
      if (i === r) continue;
      val = cloneMatrix[i][lead];
      for (let j = 0; j < columnCount; j++) {
        cloneMatrix[i][j] -= val * cloneMatrix[r][j];
      }
      saveStep(`Subtract ${val} times row ${r + 1} from row ${i + 1}`);
    }
    lead++;
  }

  return { matrix: cloneMatrix, steps };
};

const App = () => {
  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(3);
  const [matrix, setMatrix] = useState(Array(3).fill(Array(3).fill(0)));
  const [rrefMatrix, setRrefMatrix] = useState(null);
  const [steps, setSteps] = useState([]);

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newMatrix = matrix.map((row, i) =>
      row.map((col, j) => (i === rowIndex && j === colIndex ? Number(value) : col))
    );
    setMatrix(newMatrix);
  };

  const handleCalculate = () => {
    const { matrix: resultMatrix, steps: resultSteps } = computeRREF(matrix);
    setRrefMatrix(resultMatrix);
    setSteps(resultSteps);
  };

  return (
    <div>
      <header>
        <img src="/img/matrix-row-reduction-calculator-logo.png" className="logo" alt="Matrix Row Reduction Calculator" />
        <a href="/" className="logo-title">Matrix Row Reduction Calculator</a>
        <nav className="menu">
            <Navigation />
        </nav>
      </header>
      <div className="container">
        <h1>Matrix Row Reduction Calculator</h1>
        <h2>Calculate reduced row echelon form using this matrix row reduction calculator with steps</h2>
        <p>Enter the dimensions of the matrix and the values.</p>
        <p>Click 'Calculate' to see the RREF and calculation steps.</p>
        <br />
        <div className="matrix-dimensions">
          <label>
            Rows:
            <input
              type="number"
              min="1"
              max="10"
              value={rows}
              onChange={(e) => {
                const newRows = Math.max(1, Math.min(10, Number(e.target.value)));
                setRows(newRows);
                setMatrix(Array(newRows).fill(Array(cols).fill(0)));
              }}
            />
          </label>
          <label>
            Columns:
            <input
              type="number"
              min="1"
              max="10"
              value={cols}
              onChange={(e) => {
                const newCols = Math.max(1, Math.min(10, Number(e.target.value)));
                setCols(newCols);
                setMatrix(Array(rows).fill(Array(newCols).fill(0)));
              }}
            />
          </label>
        </div>

        {/* Matrix Input Component */}
        <MatrixInput matrix={matrix} handleInputChange={handleInputChange} />

        <div className="calculate-button">
          <button onClick={handleCalculate}>Calculate</button>
        </div>

        {/* Calculation Steps Component */}
        {steps.length > 0 && <Calculation steps={steps} />}

        {/* Result Matrix Component */}
        {rrefMatrix && <ResultMatrix rrefMatrix={rrefMatrix} />}
      </div>

      <Footer />
    </div>
  );
};

export default App;