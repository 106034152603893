import React from 'react';
import './App.css';

const Calculation = ({ steps }) => {
  return (
    <div className="calculation-steps">
      <br />
      <h2>Calculation Steps</h2>
      {steps.map((step, index) => (
        <div key={index}>
          <h3>Step {index + 1}: {step.operation}</h3>
          <table className="matrix-table">
            <tbody>
              {step.matrix.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((col, colIndex) => (
                    <td key={colIndex}>{col.toFixed(0)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default Calculation;